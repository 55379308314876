import axios from 'axios';
import { store } from '@/store';

export interface Building {
  dynamicId: number;
  staticId: string;
  name: string;
  type: string;
  area: number;
}

export function getBuilding() {
  axios
    .get(`https://api-sandbox-nci.spinalcom.com/api/v1/building/read`)
    .then((response) => {
      store.building.name = response.data.name;
    });
}
