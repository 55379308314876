import { reactive } from 'vue';
import { Process, Ticket } from '@/services/MaintenanceService';

export interface Store {
  selectedRoomId: string | number;
  room: {
    equipementList: [];
    equipementListNames: [];
    attributsList: [];
    controlEndpointList: [];
    roomName: string;
    occupiedStatus: boolean;
    maintenanceTickets: Ticket[];
    temperatureEndpoint?: {
      dynamicId: number;
      staticId: string;
      name: 'COMMAND_TEMPERATURE';
      type: 'Consigne';
      currentValue: number;
    };
    blindEndpoint?: {
      dynamicId: number;
      staticId: string;
      name: 'COMMAND_BLIND';
      type: 'Consigne';
      currentValue: number;
    };
    lightEndpoint?: {
      dynamicId: number;
      staticId: string;
      name: 'COMMAND_LIGHT';
      type: 'Consigne';
      currentValue: number;
    };
  };
  workflowProcessList: Process[];
  workflowId: number;
  building: {
    name: string;
  };
}

export const store: Store = reactive({
  selectedRoomId: 'not_selected',
  room: {
    equipementList: [],
    equipementListNames: [],
    attributsList: [],
    controlEndpointList: [],
    roomName: '',
    occupiedStatus: false,
    maintenanceTickets: [],
  },
  workflowProcessList: [],
  workflowId: 0,
  building: {
    name: 'Nom du batiment',
  },
});
