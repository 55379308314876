import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';
import LoginPage from '@/views/LoginPage.vue';
import RoomListPage from '@/views/RoomListPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/main',
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/room-list',
    component: RoomListPage,
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/main',
      },
      {
        path: 'room/:id',
        component: () => import('@/views/RoomPage.vue'),
      },
      {
        path: 'main',
        component: () => import('@/views/MainPage.vue'),
      },
      {
        path: 'profile',
        component: () => import('@/views/ProfilePage.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  // console.log(to, from);
});

export default router;
