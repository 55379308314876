export function getEndpointInProfile(
  data: any[],
  profileName: string,
  endpoint: string
) {
  // console.log(data, profileName, endpoint);

  const profileData: any = data.filter(
    (profileItem) => profileItem.profileName === profileName
  );

  let endpointData;
  if (profileData.length === 1) {
    endpointData = profileData[0].endpoints.filter(
      (endpointItem: any) => endpointItem.name === endpoint
    );
  } else {
    console.error(profileName, endpoint, 'profileName not found');
    return null;
  }

  if (endpointData.length === 1) {
    console.log(profileName, endpoint, endpointData[0].currentValue);
    return endpointData[0];
  }

  console.error(profileName, endpoint, 'endpoint not found');
  return null;
}

export function formatDate(dateString: number) {
  const date = new Date(dateString);
  // Then specify how you want your dates to be formatted
  return new Intl.DateTimeFormat('default', {
    dateStyle: 'long',
    timeStyle: 'short',
  }).format(date);
}
