import axios, { AxiosError } from 'axios';
import { useRouter } from 'vue-router';
import { getBuilding } from '@/services/BuildingService';

const rawAccount = localStorage.getItem('account');
let account: any = null;
let router;

export function init() {
  router = useRouter();
  initAxiosInterceptors();
  if (rawAccount) {
    setAccount(JSON.parse(rawAccount));
    getBuilding();
  } else {
    router.push('/login');
  }
}

export function setAccount(newAccount: any) {
  account = newAccount;
}

export function getAccount() {
  return account;
}

export async function login(username: string, password: string) {
  return axios.post('https://api-sandbox-nci.spinalcom.com/api/v1/auth', {
    userName: username,
    password,
  });
}

export function logout() {
  localStorage.removeItem('account');
  window.location.reload();
}

export function initAxiosInterceptors() {
  axios.interceptors.request.use((request) => {
    // add auth header with jwt if account is logged in and request is to the api url
    const isLoggedIn = account?.token;
    const isApiUrl = !request.url?.includes('login');

    if (isLoggedIn && isApiUrl) {
      request.headers.Authorization = `Bearer ${account.token}`;
    }

    return request;
  });

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error: AxiosError) {
      console.error(error);

      if (error.response?.status === 401) {
        logout();
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
}
