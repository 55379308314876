import { store } from '@/store';
import axios, { AxiosResponse } from 'axios';
import { toRaw } from 'vue';
import { formatDate, getEndpointInProfile } from '@/services/ApiDataParser';
import { getAccount } from '@/services/AuthService';
import { Ticket } from '@/services/MaintenanceService';

export async function updateRoomData(id: number | string) {
  if (id === 'not_selected') {
    return;
  }
  store.room.equipementList = (
    await axios.get(
      `https://api-sandbox-nci.spinalcom.com/api/v1/room/${id}/equipement_list`
    )
  ).data;
  console.log(toRaw(store.room.equipementList));

  store.room.equipementListNames = toRaw<any>(store.room.equipementList).map(
    (item: any) => item.name
  );

  store.room.attributsList = (
    await axios.get(
      `https://api-sandbox-nci.spinalcom.com/api/v1/node/${id}/attributsList`
    )
  ).data;
  console.log(toRaw(store.room.attributsList));

  store.room.controlEndpointList = (
    await axios.get(
      `https://api-sandbox-nci.spinalcom.com/api/v1/room/${id}/control_endpoint_list`
    )
  ).data;
  console.log(toRaw(store.room.controlEndpointList));

  store.room.occupiedStatus = getEndpointInProfile(
    toRaw(store.room.controlEndpointList),
    'KPI',
    'Occupation'
  )?.currentValue;

  store.room.temperatureEndpoint = getEndpointInProfile(
    toRaw(store.room.controlEndpointList),
    'Command',
    'COMMAND_TEMPERATURE'
  );

  store.room.blindEndpoint = getEndpointInProfile(
    toRaw(store.room.controlEndpointList),
    'Command',
    'COMMAND_BLIND'
  );

  store.room.lightEndpoint = getEndpointInProfile(
    toRaw(store.room.controlEndpointList),
    'Command',
    'COMMAND_LIGHT'
  );

  store.room.roomName = (
    await axios.get(
      `https://api-sandbox-nci.spinalcom.com/api/v1/room/${id}/read`
    )
  ).data?.name;

  store.room.maintenanceTickets = (
    await axios.get(
      `https://api-sandbox-nci.spinalcom.com/api/v1/room/${id}/ticket_list`
    )
  ).data
    .map((ticket: Ticket) => {
      ticket.date = formatDate(ticket.creationDate);
      ticket.owned = ticket.declarer_id === getAccount().userId;
      return ticket;
    })
    .reverse();
  console.log(toRaw(store.room.maintenanceTickets));
}

export async function updateTemperature(temperature: number) {
  const payload = {
    tempCurrentValue: temperature,
  };

  return axios.post(
    `https://api-sandbox-nci.spinalcom.com/api/v1/command/room/${store.selectedRoomId}/temp`,
    payload
  );
}

export async function updateBlinds(value: number) {
  const payload = {
    blindCurrentValue: value,
  };

  return axios.post(
    `https://api-sandbox-nci.spinalcom.com/api/v1/command/room/${store.selectedRoomId}/blind`,
    payload
  );
}

export async function updateLight(value: number) {
  const payload = {
    lightCurrentValue: value,
  };

  return axios.post(
    `https://api-sandbox-nci.spinalcom.com/api/v1/command/room/${store.selectedRoomId}/light`,
    payload
  );
}

export async function loadRoomStatuses(etage: any) {
  const requests = etage.children
    .map((room: any) => room.dynamicId)
    .map((id: number) =>
      axios.get(
        `https://api-sandbox-nci.spinalcom.com/api/v1/room/${id}/control_endpoint_list`
      )
    );

  const result = await Promise.all(requests);

  const statuses = result.reduce((acc, response: AxiosResponse) => {
    const id = response.request.responseURL.split('/')[6];
    const occupation = getEndpointInProfile(
      response.data,
      'KPI',
      'Occupation'
    )?.currentValue;

    if (occupation !== undefined) {
      acc[id] = occupation;
    }

    return acc;
  }, {});

  return statuses;
}
